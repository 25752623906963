<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";
import Spinner from "vue-simple-spinner";

import { virtualAccountList, virtualAccountAdd } from "@/state/helpers";
import {
  FETCH_VIRTUAL_ACCOUNTS,
  CREATE_VIRTUAL_ACCOUNT,
  FETCH_ALL_PAYMENT_CHANNEL,
  FETCH_MERCHANTS_LIST,
  FETCH_VIRTUAL_ACCOUNTS_MASTER_MERCHANT
} from "@/state/actions.type";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Layout,
    PageHeader,
    Spinner,
    Multiselect
},
  data() {
    return {
      errorMessage: "",
      createError: false,
      successfulMessage: false,
      submitted: false,
      isVirtualAccountFlow : false,
      showmodal: false,
      typesubmit: false,
      merchants: [],
      chaiPayVirtualAccounts: {
        map_id: `mapid_${(this._uid + this.$uuid.v1())
          .replace(/-/g, "")
          .substring(1, 13)
          .toUpperCase()}${this._uid}`,
        customer_name: "",
        channel_key: "EPAY_PAYOUT",
        bank_code: "WOORIBANK",
        personal_info: {
          phone: "",
          email: "",
          address: "",
          id: `pinfo_${(this._uid + this.$uuid.v1())
            .replace(/-/g, "")
            .substring(1, 13)
            .toUpperCase()}${this._uid}`,
        },
      },
    };
  },
  methods: {
    ...virtualAccountList,
    ...virtualAccountAdd,

    openModal() {
      this.resetPayoutForm();
      this.submitted = false;
      this.showmodal = true;
    },

    hideModal() {
      this.showmodal = false;
      this.submitted = false;
      this.resetPayoutForm();
    },

    resetPayoutForm() {
      this.chaiPayVirtualAccounts = {
        map_id: `mapid_${(this._uid + this.$uuid.v1())
          .replace(/-/g, "")
          .substring(1, 13)
          .toUpperCase()}${this._uid}`,
        customer_name: "",
        channel_key: "EPAY_PAYOUT",
        bank_code: "WOORIBANK",
        personal_info: {
          phone: "",
          email: "",
          address: "",
        },
      };
    },

    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const addVirtualAccount = {
        customer_name: this.chaiPayVirtualAccounts.customer_name.toUpperCase(),
        channel_key: this.chaiPayVirtualAccounts.channel_key,
        bank_code: this.chaiPayVirtualAccounts.bank_code,
        personal_info: {
          id: `${(this._uid + this.$uuid.v1())
            .replace(/-/g, "")
            .substring(1, 13)
            .toUpperCase()}${this._uid}`,
          phone: this.chaiPayVirtualAccounts.phone,
          email: this.chaiPayVirtualAccounts.email,
          address: this.chaiPayVirtualAccounts.address,
        },
        env: "",
      };
      addVirtualAccount["map_id"] =
        addVirtualAccount.customer_name.trim().replace(/ /g, "_") +
        `_${(this._uid + this.$uuid.v1())
          .replace(/-/g, "")
          .substring(1, 13)
          .toUpperCase()}${this._uid}`;
      this.submitted = false;

      this.$store
        .dispatch(`virtualaccount/${CREATE_VIRTUAL_ACCOUNT}`, addVirtualAccount)
        .then((result) => {
          setTimeout(() => {
            this.showmodal = false;
            this.successfulMessage = false;
            this.createError = false;
            this.resetPayoutForm();
          }, 3000);
          if (result.is_success == "false") {
            (this.errorMessage = result.message), (this.createError = true);
          } else {
            this.successfulMessage = true;
          }
        })
        .catch((error) => {
          this.createError = true;
          setTimeout(() => {
            this.resetPayoutForm();
            this.showmodal = false;
          }, 3000);
          console.log(error);
        });
    },
    fetchVirtualAccounts() {
      if(this.isMasterMerchant) {
        const merchants = this.merchants.map(merchant => merchant.email_id)
        this.$store.dispatch(`virtualaccount/${FETCH_VIRTUAL_ACCOUNTS_MASTER_MERCHANT}`, {
          "filters": {
            "merchants": merchants
          }
        });
      } else {
        this.$store.dispatch(`virtualaccount/${FETCH_VIRTUAL_ACCOUNTS}`);
      }
    },
    fetchPaymentChannels() {
      this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, {
        country: [],
        paymentMethod: "",
        paymentType: "PAYOUT"
      })
        .then((response) => {
          this.isVirtualAccountFlow = response && response.find(channel => channel.is_va_flow) ? true : false;
        });
    },
    merchantSelected() {
      this.fetchVirtualAccounts();
    },
    onCopySuccess() {
      this.$notify({
        type: "success",
        text: "Account No copied to clipboard.",
        closeOnClick: true,
      });
    },
  },

  computed: {
    isFetchingTransactions() {
      return this.$store.state.virtualaccount.isFetchingData;
    },
    title() {
      return this.$t("menuitems.payouts.list.virtualbank");
    },

    items() {
      return [
        {
          text: this.$t("menuitems.payouts.list.payouts"),
          active: true
        },
        {
          text: this.$t("menuitems.payouts.list.virtual_account"),
          active: true,
        },
      ];
    },

    fetchVirtualAccountsList() {
      return this.$store.state.virtualaccount.virtualAccounts;
    },

    userPermissions() {
      return this.$store.getters["auth/getUserPermissionsList"];
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },
  },

  validations: {
    chaiPayVirtualAccounts: {
      customer_name: { required },
      channel_key: { required },
      map_id: { required },
      bank_code: { required },
    },
  },

  created() {
    if(this.isMasterMerchant){
      this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
        pageIndex: 1,
        pagesize: 10,
        filters: { source: "default" },
      }).then((response) => {
        if(response.content.length > 0) {
          this.fetchVirtualAccounts();
        }
      });
    } else {
      this.fetchVirtualAccounts();
      this.fetchPaymentChannels();
    }
    
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

      <div class="row justify-content-end mr-2 mb-3">
        <multiselect
          v-if="isMasterMerchant"
          v-model="merchants"
          :options="merchantListDataFromStore"
          @input="merchantSelected"
          placeholder="Search Merchants"
          track-by="email_id"
          label="email_id"
          class="sm-multiselect col-md-3"
          :searchable="true"
          :multiple="true"
          :taggable="true"
        ></multiselect>
      </div>
  
    <button
      href="javascript:void(0);"
      v-if="userPermissions.includes('create-virtual-accounts') && isVirtualAccountFlow == true && !isMasterMerchant"
      class="btn btn-primary mb-4 "
      @click="openModal()"
    >
      {{ $t("views.payouts.virtual-account") }}
    </button>

    <div class="row">
      <div
        class="col-xl-3 col-sm-6"
        v-for="(data, index) in fetchVirtualAccountsList"
        :key="index"
      >
        <div class="card border border-light" style="height: 90%">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <p class="text-truncate font-size-14 mb-2">
                  {{ `${data.account_name}` }}
                </p>
                <p class="mb-4 text-truncate" style="cursor: pointer;" v-clipboard:copy="data.account_no" v-clipboard:success="onCopySuccess" :title="data.account_no" v-b-tooltip.hover>{{ `${ $t('views.payouts.acc_no') }: ${data.account_no}` }}</p>
              </div>
              <img :src="data.channel_logo" class="w-25" alt="">
            </div>
            <h4 class="mb-0" v-if="!['BBL_PAYOUT', 'PAYNAMICS_PAYOUT'].includes(data.channel_key)">
              {{
                data.balance.toLocaleString("en-US", {
                  style: "currency",
                  currency: data.currency,
                })
              }}
            </h4>
          </div>

          <div class="card-body border-top py-3" v-if="!['BBL_PAYOUT', 'PAYNAMICS_PAYOUT'].includes(data.channel_key)">
            <div class="text-truncate">
              <router-link
                :to="'/payouts/virtual-account/transactions?account_no=' + data.account_no"
                class="btn btn-light"
                style="width: 100%"
              >
                {{ $t("views.payouts.view-transactions") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-if="isFetchingTransactions === true"></Spinner>

    <!-- end row -->

    <div>
      <b-modal
        size="lg"
        id="modal-2"
        centered
        v-model="showmodal"
        title="Create Virtual Account"
        title-class="text-dark font-18"
        no-close-on-backdrop
        hide-footer
      >
        <form id="modal" @submit.prevent="handleSubmit">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="Benefactor Name">
                  <i class="mdi mdi-account"></i>

                  {{ $t("views.payouts.benefactor-name") }}
                  <span class="text-danger mr-1">*</span>
                  <i class="ri-information-line ml-1" id="Benefactor"></i>
                  <b-tooltip target="Benefactor">
                    {{ $t("views.payouts.party_name_msg") }}
                  </b-tooltip>
                </label>
                <input
                  type="text"
                  class="form-control"
                  style="text-transform: uppercase"
                  :placeholder="$t('views.payouts.benefactor-name')"
                  name="customer_name"
                  v-model="chaiPayVirtualAccounts.customer_name"
                  :class="{
                    'is-invalid':
                      submitted &&
                      $v.chaiPayVirtualAccounts.customer_name.$error,
                  }"
                />
                <div
                  v-if="
                    submitted &&
                    !$v.chaiPayVirtualAccounts.customer_name.required
                  "
                  class="invalid-feedback"
                >
                  {{ $t('views.payouts.required_field_msg.customer_name') }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="Bank Code">
                  <i class="mdi mdi-bank"></i>

                  {{ $t("views.payouts.bank-code") }}
                  <span class="text-danger mr-1">*</span>
                  <i class="ri-information-line ml-1" id="bank"></i>
                  <b-tooltip target="bank">
                    {{ $t('views.payouts.unique_bank_code_msg') }}
                  </b-tooltip>
                </label>

                <select
                  name="bankcode"
                  class="form-control"
                  v-model="chaiPayVirtualAccounts.bank_code"
                  :class="{
                    'is-invalid':
                      submitted && $v.chaiPayVirtualAccounts.channel_key.$error,
                  }"
                >
                  <div
                    v-if="
                      submitted &&
                      !$v.chaiPayVirtualAccounts.channel_key.required
                    "
                    class="invalid-feedback"
                  >
                    {{ $t('views.payouts.required_field_msg.bank_code') }}
                  </div>
                  <option value="WOORIBANK">WOORIBANK</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="Channel Key">
                  <i class="mdi mdi-key"></i>

                  {{ $t("views.payouts.channel-key") }}
                  <span class="text-danger mr-1">*</span>
                  <i class="ri-information-line ml-1" id="b"></i>
                  <b-tooltip target="b">
                    {{ $t('views.payouts.channel_name_msg') }}
                  </b-tooltip>
                </label>

                <select
                  name="channelkey"
                  class="form-control"
                  v-model="chaiPayVirtualAccounts.channel_key"
                  :class="{
                    'is-invalid':
                      submitted && $v.chaiPayVirtualAccounts.channel_key.$error,
                  }"
                >
                  <div
                    v-if="
                      submitted &&
                      !$v.chaiPayVirtualAccounts.channel_key.required
                    "
                    class="invalid-feedback"
                  >
                    {{ $t('views.payouts.required_field_msg.channel_key') }}
                  </div>
                  <option value="EPAY_PAYOUT">EPAY_PAYOUT</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="Address">
                  <i class="mdi mdi-home-map-marker"></i>
                  {{ $t("views.payouts.address") }}
                </label>
                <input
                  id="f"
                  type="text"
                  class="form-control"
                  placeholder="Address"
                  name="address"
                  v-model="chaiPayVirtualAccounts.address"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="Phone">
                  <i class="mdi mdi-phone mr-2"></i>
                  {{ $t("views.payouts.phone") }}
                </label>

                <input
                  type="number"
                  class="form-control"
                  name="phone"
                  placeholder="Phone Number"
                  maxlength="13"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="chaiPayVirtualAccounts.phone"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="Email">
                  <i class="mdi mdi-email-outline"></i>

                  {{ $t("views.payouts.email") }}
                </label>

                <input
                  type="email"
                  class="form-control"
                  placeholder="Email Address"
                  name="email"
                  v-model="chaiPayVirtualAccounts.email"
                />
              </div>
            </div>
          </div>

          <div class="text-right">
            <b-button class="mr-1" variant="light" @click="hideModal"
              >{{ $t("button.close") }}
            </b-button>

            <button
              type="submit"
              class="btn btn-primary ml-1"
            >
              {{ $t("views.payouts.create-account") }}
            </button>
          </div>
        </form>

        <div>
          <b-modal
            centered
            title="Virtual Account"
            v-model="createError"
            title-class="text-dark font-18"
            no-close-on-backdrop
            hide-footer
          >
            <p>{{ errorMessage }}</p>
          </b-modal>
        </div>

        <div>
          <b-modal
            centered
            title="Virtual Account"
            v-model="successfulMessage"
            title-class="text-dark font-18"
            no-close-on-backdrop
            hide-footer
          >
            <div
              class="failure-modal"
              style="display: grid; justify-content: center"
            >
              <img src="@/assets/images/success.png" alt height="100" />
              <p>{{ $t("views.payouts.virtual-successfull") }}</p>
            </div>
          </b-modal>
        </div>
      </b-modal>
    </div>
    <!-- end modal -->
  </Layout>
</template>

<style scoped>
input:focus:required:invalid {
  color: green;
}
::v-deep .btn{
  border-radius: 4px;
}
.form-control{
  font-size: 14px;
}
label{
  font-size: 14px;
}
</style>









